.poltica-de-privacidad-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.poltica-de-privacidad-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-primary-100);
}
.poltica-de-privacidad-text {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  text-align: center;
}
.poltica-de-privacidad-text03 {
  margin-top: var(--dl-space-space-doubleunit);
  text-align: justify;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-doubleunit);
}
@media(max-width: 767px) {
  .poltica-de-privacidad-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .poltica-de-privacidad-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
}
