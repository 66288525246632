.kit-digital-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.kit-digital-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #003EB3;
  color: #fff;
}

.kit-digital-text {
  font-size: 3rem;
  text-align: center;
}

.kit-digital-container1 {

  margin: 5%;
  margin-top: 1%;
}

.kit-digital-content-sbs {
  display: flex;
  flex-direction: row;
  margin: 5em;
}

.kit-digital-container-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fffa;
  margin-right: 1em;
}

.kit-digital-container-right {
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fffa;
}
.kit-digital-text03 {
  align-self: center;
}
.kit-digital-text06 {
  align-self: center;
  text-align: center;
}
.kit-digital-text09 {
  align-self: center;
}
.kit-digital-text12 {
  text-align: center;
}
.kit-digital-text15 {
  align-self: center;
}
.kit-digital-text18 {
  text-align: center;
}
.kit-digital-link {
  color: var(--dl-color-primary-100);
  align-self: center;
  text-decoration: underline;
}
.kit-digital-steps {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.kit-digital-container3 {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  width: 100%;
}

.kit-digital-step {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1em;
  max-width: 100%;
}

.kit-digital-text20 {
  color: grey;
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.kit-digital-stepname {
  font-size: 1.15rem;
  width: 100%;
  text-align: center;
  margin-top: 1em;
}

.kit-digital-text23 {
  color: grey;
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
  
}
.kit-digital-text25 {
  font-size: 1.15rem;
}
.kit-digital-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.kit-digital-text28 {
  color: grey;
  font-size: 4rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}
.kit-digital-text30 {
  font-size: 1.15rem;
}
@media(max-width: 991px) {
  .kit-digital-text19 {
    text-align: center;
  }
  .kit-digital-text20 {
    text-align: center;
  }
  .kit-digital-text22 {
    text-align: center;
  }
  .kit-digital-text23 {
    text-align: center;
  }
  .kit-digital-text25 {
    text-align: center;
  }
  .kit-digital-text26 {
    text-align: center;
  }
  .kit-digital-text28 {
    text-align: center;
  }
  .kit-digital-text30 {
    text-align: center;
  }
  .kit-digital-content-sbs {
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0em;
  }

  .kit-digital-container1 {
    flex-direction: column;
  }
  .kit-digital-container4 {
    flex-direction: column;
  }
  .kit-digital-container5 {
    flex-direction: column;
  }

  .kit-digital-container3 {
    flex-direction: column;
  }

  .kit-digital-container-right {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .kit-digital-banner {
    padding-left: 32px;
    padding-right: 32px;
  }

  .kit-digital-step {
    margin-left: 0em;
  }
}
@media(max-width: 479px) {
  .kit-digital-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .kit-digital-container-right {
    margin-top: 1.5em;
  }
}

.contact-cta-btn-container-kit {
  float: right;
  margin-bottom: 1em;
  margin-top: 1em;
}