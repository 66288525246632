.kitdigital-footer {
  width: 100%;
  object-fit: contain;
  filter: drop-shadow(2px 2px 4px #111);
}

.pricing-img {
  width: 100%;
  
}

.oficina-virtual-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.oficina-virtual-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.oficina-virtual-text {
  font-size: 3rem;
  text-align: center;
  color: black;
}
.oficina-virtual-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.oficina-virtual-container2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  border-color: #3c9a52;
  border-style: inset;
  border-width: 0px;
  flex-direction: column;
}
.oficina-virtual-ul {
  list-style-type: disc;
}
.oficina-virtual-ul1 {
  list-style-type: circle;
}
.oficina-virtual-ul2 {
  list-style-type: circle;
}
@media(max-width: 767px) {
  .oficina-virtual-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .oficina-virtual-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .oficina-virtual-container2 {
    flex: 1;
    flex-direction: column;
  }
}
