.aviso-legal-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.aviso-legal-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #003EB3;
}
.aviso-legal-text {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
}
.aviso-legal-container1 {
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.aviso-legal-text001 {
  text-align: justify;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-doubleunit);
}
@media(max-width: 767px) {
  .aviso-legal-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .aviso-legal-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
}
