.info-site-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.info-content-box {
    /*background-color:lightgreen;*/
    margin: 1em;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    padding: 1em;
}

.info-content-box h1,h2 {
    text-align: center;
}

@media (max-width: 760px){
    .info-content-box div {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .info-content-box {
        margin: 0em;
        padding: 0em;
        margin-top: 1em;
    }
}