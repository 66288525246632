.services-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1em;
}

.service {
    background-color: blue;
    color: white;
    margin: 0.3em;
    min-height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    text-shadow: 1px 1px 20px black;
    flex: 1 0 24%;
}

.service img {
    height: 100%;
    filter: invert(97%) sepia(4%) saturate(322%) hue-rotate(195deg) brightness(118%) contrast(100%);
    position: absolute;
    opacity: 0.3; 
}

.service p {
    margin-left: 3em;
    margin-right: 3em;
    text-align: center;
    align-self: center;
}

.contact-cta-btn-container-cloud {
    float: right;
    margin-top: 3em;
    margin-bottom: 1em;
}

#infrah1 {

    margin-top: 1em;
}
.servicios-nube {
    background-image: url("/files/nube.jpg");
}

.servicios-ciberseguridad {
    background-image: url("/files/candado.jpg");
    background-position: -30px;
}

.servicios-voip  {
    background-image: url("/files/support-carousel.jpg");
}

.servicios-iot {
    background-image: url("/files/iot.png")

}

.timeline1-image2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    margin-top: 2em;
}