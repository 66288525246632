.icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: var(--dl-space-space-doubleunit);
  fill:limegreen;
}

.get-in-touch-info-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  flex: 1 0 auto;
}

.get-in-touch-info {
  display: flex; 
  flex-direction: column;
  align-items: left;
  align-self: center;
  max-width: 80%;
}

.sbs-box {
  display: flex;
  flex-direction: row;
}

.info-left {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info-contacto {
  display: inline-block;
  margin-right: 1em;
  min-width: 50%;
}

.info-right {
  background-color: #0004;
  padding: 0.5em;
  display: inline-block;
  border-color: black;
  border-width: 1px;
  width: 100%;
}

.info-right select {
  text-overflow: ellipsis;
  width: 100%
}

.informacin-de-contacto-container2 h1 {
  text-align: center;
}

.form-input {
  border-color: gray;
  border-width: 1px;
  width: 100%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;  
  
}

#message-box {
  min-height: 1em;
  min-width: 100%;
  max-width: 100%;
}

#form-submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#lopd-label {
  margin-left: 0.3em;
}

.informacin-de-contacto-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
}
.informacin-de-contacto-banner {
  width: 100%;
  display: flex;
  padding: 0.5em;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-primary-100);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: larger;
}

.get-in-touch-text {
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1em;
}

#info-img {
  z-index: -10000;
  user-select: none;
  position: absolute;
  left:0;
  top:0;
  height: 100%;
  width: auto;
  min-width: 100%;
  opacity: 0.25;
  object-fit: cover;
}

.informacin-de-contacto-container1 {
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
.informacin-de-contacto-text03 {
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
}
.informacin-de-contacto-container2 {
  margin-left: 10em;
  margin-right: 10em;
  margin-top: 2em;
  margin-bottom: 2em;
}
.info-contacto-item { 
  width: 100%;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.informacin-de-contacto-link {
  color: var(--dl-color-primary-300);
  text-decoration: none;
}

@media(max-width: 767px) {
  .informacin-de-contacto-banner {
    padding-left: 32px;
    padding-right: 32px;
  }

  .get-in-touch-info-item svg {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .informacin-de-contacto-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .form-input {
    font-size: medium;
  }
}

@media(max-width: 1150px) {
  .sbs-box {
    flex-direction: column;
  }
  .separator {
    display: none;
  }
}