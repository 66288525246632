.construction-notice {
  background-color: red;
  width: 100%;
  font-size: large;
  height: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.navbar-container-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.navbar-container-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.navbar-container-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.navbar-container-navlink {
  display: contents;
}
.navbar-container-image {
  width: 9em;
  align-self: center;
  object-fit: cover;
  margin-right: 5em;
  text-decoration: none;
}
.navbar-container-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.navbar-container-icon {
  width: 36px;
  height: 36px;
}
.navbar-container-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.navbar-container-link {
  margin-right: 3em;
  text-decoration: none;
}
.navbar-container-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-container-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
  text-wrap: nowrap;
  font-size: mediu;
}
.navbar-container-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.navbar-container-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.navbar-container-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.navbar-container-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container-icon2 {
  width: 24px;
  height: 24px;
}
.navbar-container-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link4 {
  text-decoration: none;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 30px;
  height: 25px;
  margin: 0.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  top: 0px
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.the-actual-burger-menu {
  display: none;
}

@media(max-width  : 867px) {
  .the-actual-burger-menu {
    display: block;
  }
  .navbar-container-icon {
    fill: var(--dl-color-gray-white);
  }
  .navbar-container-links-container {
    display: none;
  }
}
@media(max-width: 479px) {
  .navbar-container-cta-btn {
    display: none;
  }

}
