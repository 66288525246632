.kitdigital-footer {
  width: 100%;
  object-fit: contain;
}

.puestots-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.puestots-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.puestots-text {
  font-size: 3rem;
  text-align: center;
  color: black;
}
.puestots-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.puestots-container2 ul li {
  margin-left: 4em;
  margin-right: 4em;
}


.puestots-container2 h2 {
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}

.puestots-container2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  border-color: #3c9a52;
  border-style: inset;
  border-width: 0px;
  flex-direction: column;
  text-align: justify;
}
.puestots-ul {
  list-style-type: disc;
}
.puestots-ul1 {
  list-style-type: circle;
}
.puestots-ul2 {
  list-style-type: circle;
}
@media(max-width: 767px) {
  .puestots-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .puestots-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .puestots-container2 {
    flex: 1;
    flex-direction: column;
  }

  .puestots-text {
    font-size: 2rem;
  }
}
