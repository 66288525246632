.steps {
    width: 98%;
    min-height: 10em;
    background-color: aliceblue;
    margin: 1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.partner-table {
    background-color: grey;
    width: 98%;
    margin: 1%;
}

.partner-table th {
    background-color: blue;
    color: white;
    width: 10em;
}

.partner-list {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
}

.partner-list img {
    max-width: 7em;
}

.subtitle {
    text-align: center;
}

.infraestructura-text {
    text-align: justify;

}

.infraestructura-text-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

.infraestructura-features {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-tenunits);
    border-color: rgba(0, 0, 0, 0);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: var(--dl-color-success-700);
}

.infraestructura-features-card {
    width: 30%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .infraestructura-icon-container {
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  .infraestructura-icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }

  .infraestructura-heading {
    text-align: left;
    margin-bottom: var(--dl-space-space-fiveunits);
  }

  @media(max-width: 867px) {
    .infraestructura-features-card {
        width: 100%;
    }
  }