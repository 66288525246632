.logo-header {
  filter: drop-shadow(5px 5px 4px #000);
}

.home-timeline-card img {
  height: auto;
  width: 100%;
}

.home-timeline-card {
  margin: 3em;
}

.mobile-links-section {
  text-align: center;
  display: none;
}

.mobile-links-section a {
  text-decoration: underline;
  display: block;
}

.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-content-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}

.home-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-features {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  border-color: rgba(0, 0, 0, 0);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-success-700);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text02 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-features-card {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text05 {
  color: rgb(255, 255, 255);
  text-align: left;
}
.home-features-card1 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon03 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text15 {
  color: #ffffff;
  text-align: left;
}
.home-features-card2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon06 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text16 {
  color: #ffffff;
  text-align: left;
}
.home-features-card3 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon09 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text17 {
  color: #ffffff;
  text-align: left;
}

.home-text18 {
  color: #ffffff;
  text-align: left;
}

.home-features-card4 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon12 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading5 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-features-card5 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container5 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon15 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading6 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text19 {
  color: #ffffff;
  text-align: left;
}
.home-section-separator {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text20 {
  font-size: 3rem;
  text-align: center;
}
.home-text21 {
  max-width: 1400px;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.home-link {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link:hover {
  transform: scale(1.02);
}
.home-get-in-touch {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-text22 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-content-container1 {
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-locations-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-location-1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.home-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.home-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.contact-cta-btn-container {
  margin: 2em;
  margin-left: auto;
}

#home-subtitle {
  font-weight: bold;
  font-size: x-large;
}

.contact-cta-btn {
  color:rgb(22, 62, 100);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 10px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  border: 2px solid rgb(0, 176, 80);
  text-decoration: none;
  font-weight: bold;
  text-wrap: nowrap;
}

.home-hero {
  height: 35vh;
}

.home-hero img {
  height: 35vh;
  width: auto;
  object-fit: cover;
}

.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-content-container {
    flex-direction: column-reverse;
  }
  .home-hero-text {
    width: 80%;
  }
  .home-text21 {
    max-width: 100%;
  }
  .home-locations-container {
    flex-wrap: wrap;
  }
  .home-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 867px) {
  .home-timeline-card {
    margin: 0px;
  }

  .home-hero {
    height: 20vh;
  }

  .home-hero img {
    height: 20vh
  }

  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-features-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card4 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card5 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text21 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-content-container1 {
    width: 100%;
    flex-direction: column;
  }
  .home-locations-container {
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .home-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-hero-text {
    width: 100%;
  }
  .home-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-features-card {
    width: 100%;
  }
  .home-features-card1 {
    width: 100%;
  }
  .home-features-card2 {
    width: 100%;
  }
  .home-features-card3 {
    width: 100%;
  }
  .home-features-card4 {
    width: 100%;
  }
  .home-features-card5 {
    width: 100%;
  }
  .home-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-content-container1 {
    padding-left: 0px;
  }
  .home-location-1 {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
