.footer-container-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-container-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.footer-container-container {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.footer-container-container a {
  margin-right: 3em;
  font-size: medium;
}

.footer-container-navlink {
  text-decoration: none;
}
.footer-container-link {
  text-decoration: none;
}
.footer-container-navlink1 {
  text-decoration: none;
}
.footer-container-navlink2 {
  text-decoration: none;
}
.footer-container-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.footer-container-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.footer-container-root-class-name {
  flex: 1;
}





@media(max-width: 479px) {
  .footer-container-footer {
    flex-direction: column;
  }
  .footer-container-copyright-container {
    flex-direction: row;
  }

  .footer-container-container {
    margin-bottom: 1em;
  }

  .footer-container-container a {
    margin: 0em;
  }

}
