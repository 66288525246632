.nosotros-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.nosotros-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #003EB3;
}
.nosotros-text {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
}

.separator {
  margin: 0.5em;
}

.div-nosotros-left {
  background-color: #fffa;
  position: relative;
  left: 0;
  text-align: justify;
  width: 100%;
  box-sizing: border-box;
  margin-right: 5em;
  margin-left: 5em;
}

.div-nosotros-left p {
  margin-top: 0.5em;
}

.div-nosotros-right {
  position: relative;
  right: 0;
  max-width: 50%;
  overflow: hidden;
}

.div-nosotros-right img {
  min-height: 100%;
}

.contact-cta-btn-container-nosotros {
  float: right;
  margin-top: 1em;
  margin-bottom: 1em;
}

.nosotros-content-box-content {
  display: flex;
  margin-top: 2em;
}

.nosotros-content-box h1 {
  text-align: center;
}

.nosotros-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
}
.nosotros-text001 {
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-doubleunit);
}
@media(max-width: 1150px) {
  .nosotros-banner {
    padding-left: 32px;
    padding-right: 32px;
  }

  .separator {
    display: none;
  }

  .div-nosotros-right {
    display: none;
  }

  .div-nosotros-left {
    margin: 0;
  }
}
@media(max-width: 479px) {
  .nosotros-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
}
