.centralita-cloud-title {
    text-align: center;
}

.centralita-benefits-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.centralita-benefits-container div {
    min-width: 33%;
    height: 15em;
    background-color: bisque;
    border: 1px solid black;
}
